<script setup>
	// Composables
	import { getNowSafely, isSameDate, isSameMonth, isSameYear } from '~/composables/helpers/date';

	// Props
	const props = defineProps({
		page: {
			type: Object,
			required: true,
			default: () => ({}),
		},
		blockIndex: {
			type: Number,
		},
	});
	const { page } = toRefs(props);
	const programOrEvent = computed(() => {
		return page?.value?.postMeta?.force || page.value?.content || {};
	});

	// Variables
	const now = await getNowSafely();
	const selectedDate = ref('');

	const ticketCounts = ref([]);
	const soldOutUpdating = ref(true);
	const soldOut = ref(false);

	// Methods
	const formatDates = (type, dates) => {
		switch (type) {
			case 'builder':
				return dates?.map((date, index) => {
					return formatDate(new Date(date.start), new Date(date.end), date.dateLink);
				});
			case 'tessitura':
				if (dates?.length) {
					const sortedDates = dates.sort((a, b) => new Date(a.performanceDateTime) - new Date(b.performanceDateTime));
					return sortedDates?.map((date) => {
						return formatDate(new Date(date.performanceDateTime), new Date(date.performanceDateTime));
					});
				}
		}
	};

	const displayDatesByYear = ref({});

	const formatDate = (start, end, link = false) => {
		const startDate = getDate(new Date(start), false);
		const startTime = getTime(new Date(start));
		const endDate = getDate(new Date(end), false);
		const endTime = getTime(new Date(end));
		const sameDay = isSameDate(new Date(start), new Date(end));
		const sameMonth = isSameMonth(new Date(start), new Date(end));
		const sameYear = isSameYear(new Date(start), new Date(end));
		const sameTime = startTime === endTime;
		const endDay = new Date(end).getDate();
		const year = new Date(end).getFullYear();

		let dateString = '';

		if (sameDay) dateString = endDate;
		else if (sameMonth) dateString = `${startDate}-${endDay}`;
		else if (sameYear) dateString = `${startDate} - ${endDate}`;
		else dateString = `${getDate(new Date(start), true)} - ${getDate(new Date(end), true)}`;

		const newDate = {
			startDateTimestamp: start,
			startDate,
			startTime,
			endDateTimestamp: end,
			endDate,
			endTime,
			sameDay,
			sameTime,
			sameYear: sameYear,
			dateString,
			timestamp: start,
			year: year,
		};

		if (link) newDate.dateLink = link;

		if (displayDatesByYear.value[year]) displayDatesByYear.value[year].push(newDate);
		else displayDatesByYear.value[year] = [newDate];

		return newDate;
	};

	const getDate = (date, year) => {
		const options = {
			month: 'long',
			day: 'numeric',
			timeZone: 'America/Chicago',
		};
		if (year) options.year = 'numeric';
		return new Intl.DateTimeFormat('en-US', options).format(date);
	};

	const getTime = (date) => {
		return new Intl.DateTimeFormat('en-US', {
			hour: 'numeric',
			minute: 'numeric',
			timeZone: 'America/Chicago',
		}).format(date);
	};

	const save = (data) => {
		const email = data.soldOutEventEmail || data.expiredEventEmail;
		navigateTo(`/subscribe?email=${email}&interest=104`);
	};

	const formatSlide = (item) => {
		return {
			image: {
				url: item.image,
			},
			caption: item.caption,
			alt: item?.alt || '',
		};
	};

	const notLastItem = (index, length) => {
		return index + 1 < length;
	};

	const updateSoldOut = async () => {
		let state = false;

		if (data.value?.soldOut) state = true;

		if (!hasBuilderDates.value) {
			const performanceIds = sortedPerformances.value?.map((performance) => performance.id);
			const hasTickets = [];
			if (performanceIds && performanceIds.length) {
				try {
					ticketCounts.value = await $fetch('/api/tn/ticket-count?performanceIds=' + performanceIds?.join(','));
					for (const ticketCount of ticketCounts.value) {
						if (ticketCount.count > 0) hasTickets.push(ticketCount);
					}
				} catch (error) {
					ticketCounts.value = 0;
				}
			}

			if (hasTickets.length === 0) state = true;
		}

		soldOut.value = state;
		soldOutUpdating.value = false;
	};

	// Computed Values
	const data = computed(() => programOrEvent.value?.data || {});
	const sync = computed(() => data.value?.tessituraSync || {});
	const prices = computed(() => sync.value?.prices || []);

	const sortedPerformances = ref();
	const setSortedPerformances = () => {
		const filteredPerformances = performances.value.filter(
			(performance) => new Date(performance?.performanceDateTime) > now.value
		);

		sortedPerformances.value = filteredPerformances.sort(
			(a, b) => new Date(a?.performanceDateTime) - new Date(b?.performanceDateTime)
		);
	};

	const tessStartDate = ref();
	const setTessStartDate = () => {
		if (!performances.value.length) return false;
		tessStartDate.value = performances.value[0]?.performanceDateTime;
	};

	const tessEndDate = ref();
	const setTessEndDate = () => {
		if (!sortedPerformances.value.length) return false;
		tessEndDate.value = sortedPerformances.value[sortedPerformances.value.length - 1]?.performanceDateTime;
	};

	const nextTessLink = ref();
	const setNextTessLink = () => {
		if (!sortedPerformances.value.length) return false;
		if (hasBuilderDates.value) return false;
		nextTessLink.value = sortedPerformances.value[0]?.link;
	};

	const sortedBuilderStartDates = ref();
	const setSortedBuilderStartDates = () => {
		sortedBuilderStartDates.value = builderDates.value.sort((a, b) => new Date(a?.start) - new Date(b?.start));
	};

	const sortedBuilderEndDates = ref();
	const setSortedBuilderEndDates = () => {
		sortedBuilderEndDates.value = builderDates.value.sort((a, b) => new Date(a?.end) - new Date(b?.end));
	};

	const builderStartDate = ref();
	const setBuilderStartDate = () => {
		if (!sortedBuilderStartDates.value.length) return false;
		builderStartDate.value = sortedBuilderStartDates.value[0]?.start;
	};

	const builderEndDate = ref();
	const setBuilderEndDate = () => {
		const length = sortedBuilderEndDates.value.length;
		if (!length) return false;
		builderEndDate.value = sortedBuilderEndDates.value[length - 1]?.end;
	};

	const endDate = ref();
	const setEndDate = () => {
		const tessEnd = tessEndDate.value ? new Date(tessEndDate.value) : false;
		const builderEnd = builderEndDate.value ? new Date(builderEndDate.value) : false;

		endDate.value = tessEnd > builderEnd ? tessEnd : builderEnd;
	};

	const uniqueTimes = ref([]);
	const setUniqueTimes = () => {
		const set = new Set();
		for (const date of dates.value) {
			const justStart = date.startTime && !date.endTime;
			const both = date.startTime && date.endTime;
			let key = false;
			if (justStart) key = date.startTime;
			if (both) key = `${date.startTime} - ${date.endTime}`;
			if (both && date.sameTime) key = date.startTime;

			if (key) set.add(key);
		}

		uniqueTimes.value = Array.from(set);
	};

	const button = computed(() => {
		const button = {
			label: 'Register',
			title: `Register for ${data.value?.title}`,
			disabled: true,
		};

		if (data.value.button) {
			button.label = data.value.button.label || button.label;
			button.title = data.value.button.title || button.title;
			button.link = data.value.button.link || button.link;
			button.disabled = data.value.button.link ? false : true;
		}

		if (selectedDate.value && selectedDate.value.dateLink) {
			button.disabled = false;
			button.link = selectedDate.value.dateLink;
		}

		return button;
	});

	const price = computed(() => {
		let priceString = false;

		const priceRange = data.value?.priceRange;

		if (!priceRange) return false;
		if (priceRange.min == priceRange.max) priceString = `$${priceRange.min}`;
		if (!priceRange.min && !priceRange.max) priceString = false;
		if (priceRange.min && !priceRange.max) priceString = `$${priceRange.min}`;
		if (!priceRange.min && priceRange.max) priceString = `$${priceRange.max}`;
		if (priceRange.min && priceRange.max) priceString = `$${priceRange.min} - $${priceRange.max}`;

		return priceString;
	});

	const isExpired = ref();

	// Lifecycle Hooks

	const dates = ref([]);
	const builderDates = ref([]);
	const performances = ref([]);

	const setDates = () => {
		if (hasBuilderDates.value) dates.value = formatDates('builder', builderDates.value);
		else if (performances.value?.length) dates.value = formatDates('tessitura', performances.value);
	};

	const hasBuilderDates = ref();

	const init = () => {
		builderDates.value = data.value?.dates;
		hasBuilderDates.value = builderDates?.value?.length > 0;

		performances.value = sync.value?.performances;
		// Builder Dates Logic
		if (hasBuilderDates.value) {
			setSortedBuilderStartDates();
			setSortedBuilderEndDates();
			setBuilderStartDate();
			setBuilderEndDate();
		}

		// Tessitura Dates Logic
		if (!hasBuilderDates.value && performances.value?.length) {
			setSortedPerformances();
			setTessStartDate();
			setTessEndDate();
			setNextTessLink();
		}

		setDates();
		if (dates.value?.length == 1) {
			if (hasBuilderDates.value) {
				selectedDate.value = dates.value[0];
			} else {
				const nextDate = sortedPerformances.value.find((performance) => performance.link === nextTessLink.value);
				if (nextDate) selectedDate.value = nextDate;
			}
		}

		setEndDate();
		setUniqueTimes();
		isExpired.value = now.value > endDate.value;
	};

	// Format all the data
	init();

	const hasTickets = (id) => {
		const ticketCount = ticketCounts.value.find((ticket) => ticket.id === id);
		if (!ticketCount) return false;
		return ticketCount?.count > 0;
	};

	onMounted(async () => {
		await updateSoldOut();
	});
</script>

<template>
	<section class="block block-program-detail-header bg-white" tabindex="-1">
		<div class="container">
			<h2 v-if="data?.title" v-html="data?.title" />
			<div class="content-and-carousel-wrapper">
				<div class="content">
					<div class="summary rich-text body-lg">
						<p v-if="data?.description" v-html="data?.description" />
					</div>
					<div v-if="!isExpired" class="details">
						<template v-if="soldOutUpdating">
							<MessLoading />
						</template>
						<template v-else>
							<div class="dates-wrapper">
								<p class="h6">Date{{ dates.length > 1 ? 's' : '' }}</p>
								<p class="h4">
									<template v-if="dates.length <= 3">
										<template v-for="(datesByYear, year, index) in displayDatesByYear" :key="index">
											<template v-for="(date, index) in datesByYear">
												<span>{{ date.dateString }}</span>
												<span v-if="notLastItem(index, datesByYear.length)"> & </span>
												<span v-else-if="date.sameYear"> {{ ', ' + date.year + ' ' }} </span>
											</template>
											<span v-if="notLastItem(index, Object.keys(displayDatesByYear).length)"> & </span>
										</template>
									</template>
									<template
										v-else-if="isSameYear(dates[0].startDateTimestamp, dates[dates.length - 1].endDateTimestamp)"
									>
										<span
											>{{ dates[0].startDate }} - {{ dates[dates.length - 1].endDate }},
											{{ dates[dates.length - 1].year }}</span
										>
									</template>
									<template v-else>
										<span
											>{{ dates[0].startDate }}, {{ dates[0].year }} - {{ dates[dates.length - 1].endDate }},
											{{ dates[dates.length - 1].year }}</span
										>
									</template>
								</p>
							</div>

							<div class="times-wrapper" v-if="!hasBuilderDates && dates.length > 1">
								<p class="h6">Date</p>
								<div class="select-wrapper">
									<select
										ref="date"
										v-model="selectedDate"
										class="select"
										name="select-pe-date"
										id="select-pe-date"
										aria-label="Select a date"
									>
										<template v-for="date in sortedPerformances" :key="date.performanceDateTime">
											<option :value="date" v-if="new Date(date.performanceDateTime) > now && hasTickets(date.id)">
												{{ getDate(new Date(date.performanceDateTime), false) }}
											</option>
										</template>
									</select>
									<ProjectSvg class="arrow" type="arrowDown" />
								</div>
							</div>

							<div class="times-wrapper" v-if="hasBuilderDates && dates.length > 1">
								<p class="h6">Date</p>
								<div class="select-wrapper">
									<select
										ref="date"
										v-model="selectedDate"
										class="select"
										name="select-pe-date"
										id="select-pe-date"
										aria-label="Select a date"
									>
										<template v-for="date in dates" :key="date.dateString">
											<template v-if="new Date(date.startDateTimestamp) > now">
												<option v-if="isSameYear(date.startDateTimestamp, date.endDateTimestamp)" :value="date">
													{{ date.dateString }}, {{ date.year }}
												</option>
												<option v-else :value="date">
													{{ date.dateString }}
												</option>
											</template>
										</template>
									</select>
									<ProjectSvg class="arrow" type="arrowDown" />
								</div>
							</div>

							<div class="times-wrapper" v-if="selectedDate">
								<p class="h6">Time</p>
								<p class="body-lg">
									<template v-if="hasBuilderDates">
										<span v-html="selectedDate.startTime" />
										<span v-if="!selectedDate.sameTime"> - </span>
										<span v-if="!selectedDate.sameTime" v-html="selectedDate.endTime" />
									</template>
									<span v-else v-html="getTime(new Date(selectedDate.performanceDateTime))" />
								</p>
							</div>

							<div class="location-wrapper" v-if="data?.location">
								<p class="h6">Location</p>
								<p v-if="data?.location" v-html="data.location" class="body-lg"></p>
							</div>

							<div class="price-wrapper" v-if="hasBuilderDates && price">
								<p class="h6">Price</p>
								<p class="body-lg">{{ price }}</p>
							</div>

							<template v-if="soldOut">
								<h3 class="h4 sold-out-title">This Event is Sold Out</h3>
								<p>Sign up to get notified for future events!</p>

								<FormKit type="form" name="soldOutForm" id="soldOutForm" @submit="save" :actions="false">
									<FormKit
										name="soldOutEventEmail"
										type="email"
										id="soldOutEventEmail"
										label="Email Address"
										placeholder="Please enter your email address"
										validation="email"
										validation-visibility="live"
										outer-class="email-outer"
									/>
									<FormKit
										type="submit"
										id="soldOutEventSubmit"
										label="Submit"
										name="emailSubmit"
										wrapper-class="project-button red-fill"
										@submit="save"
									/>
								</FormKit>
							</template>

							<template v-else>
								<TemplatesToursPricing
									v-if="!hasBuilderDates"
									:isTour="false"
									:prices="prices"
									:button="button"
									:performanceId="selectedDate?.id || false"
									:productionSeasonId="selectedDate?.productionSeasonId || selectedDate?.productionSeason || false"
								/>
								<ProjectButton
									v-else
									:disabled="button.disabled"
									:label="button.label"
									:link="button.link"
									:title="button.title"
									version="red-fill"
									:force-internal-link="true"
								/>
							</template>
						</template>
					</div>

					<div v-else>
						<h3 class="h4">This Event Has Passed</h3>
						<p>Sign up to get notified for future events!</p>

						<FormKit type="form" name="expiredEventForm" id="expiredEventForm" @submit="save" :actions="false">
							<FormKit
								type="email"
								label="Email Address"
								name="expiredEventEmail"
								id="expiredEventFormEmail"
								placeholder="Please enter your email address"
								validation="email"
								validation-visibility="blur"
								outer-class="email-outer"
							/>
							<FormKit type="submit" label="Submit" wrapper-class="project-button red-fill" @submit="save" />
						</FormKit>
					</div>
				</div>

				<div class="carousel">
					<ProjectCarousel
						v-if="data?.imageList"
						:items="data?.imageList"
						:bullets="true"
						bulletStyle="secondary"
						arrowPrefix="arrow"
					>
						<template #item="data">
							<FragmentsCarouselsSlideWithCaption v-if="data[0]" :item="formatSlide(data[0])" />
						</template>
					</ProjectCarousel>
					<div v-else>
						<h2>No images in the Image List</h2>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<style lang="scss">
	.block-program-detail-header {
		.content-and-carousel-wrapper {
			@media (min-width: $mobile) {
				display: flex;
			}
			.content {
				margin-bottom: 30px;

				@media (min-width: $mobile) {
					flex: 0 0 50%;
					margin-bottom: 0;
				}
				@media (min-width: $tablet) {
					flex: 1 1 555px;
				}

				.summary,
				.dates-wrapper {
					margin-bottom: 20px;

					@media (min-width: $mobile) {
						margin-bottom: 35px;
					}

					.h4 {
						span {
							font-size: inherit;
							font-weight: inherit;
							text-transform: none;
						}
					}
				}

				.details {
					.sold-out-title {
						color: var(--red);
					}
				}

				.times-wrapper,
				.location-wrapper {
					margin-bottom: 20px;
				}

				.price-wrapper {
					margin-bottom: 30px;

					@media (min-width: $mobile) {
						margin-bottom: 45px;
					}
				}

				.select-wrapper {
					position: relative;
					// width: 45ch;

					@media (min-width: $tablet) {
						width: 45ch;
						select {
							width: 45ch;
						}
					}

					select {
						width: 100%;
						height: 100%;
						padding: 1rem;
						padding-right: 2.5rem;
						border: 1px solid var(--black);
						color: var(--black);
						font-size: 16px;
						font-weight: 350;
						line-height: 156.1%;
						letter-spacing: 0.24px;
						flex-grow: 1;
						position: relative;
						pointer-events: all;
						cursor: pointer;
					}
					.arrow {
						position: absolute;
						right: 0.75rem;
						width: 1rem;
						height: 1rem;
						top: 50%;
						transform: translateY(-50%);
						user-select: none;
						pointer-events: none;
					}
				}
			}

			.carousel {
				@media (min-width: $mobile) {
					width: 50%;
					margin-left: 20px;
				}

				@media (min-width: $tablet) {
					flex: 1 1 785px;
				}

				.mess-image img {
					aspect-ratio: 16 / 9;
					&.full-sized-image {
						overflow: hidden;
					}
				}
			}
		}

		form {
			display: flex;
			flex-wrap: wrap;
			margin-top: 2rem;
			gap: 0.5rem;
			align-items: flex-end;

			.formkit-label {
				font-size: calc(14 / var(--base-fs) * 1rem);
				font-weight: 900;
				line-height: 1.78571;
				letter-spacing: 1.12px;
				text-transform: uppercase;
				margin-bottom: 0.5em;
			}

			.formkit-messages {
				width: 100%;
				margin-top: 1rem;
			}

			input[type='text'],
			input[type='email'] {
				width: 100%;
				padding: 10px 16px;
				background-color: var(--white);
				border: 1px solid var(--black);
				border-radius: 0;
			}

			.email-outer {
				width: 100%;
				@media (min-width: $mobile) {
					width: calc(100% - 8rem);
				}
			}

			.formkit-outer[data-family='button'] {
				.project-button {
					padding: 0;

					button {
						border: 0;
						cursor: pointer;
						padding: 12px 28px;
						font-size: 12px;
						font-weight: 900;
						text-transform: uppercase;
						width: 100%;
						height: 47px;
					}
				}
			}
		}
	}
</style>

<!--
programOrEvent = {
  "ownerId": "e61abde79d954ddab2b6092044c4b5a1",
  "lastUpdateBy": null,
  "createdDate": 1716551317913,
  "id": "8964fbdb195a45f58ea87697015704d2",
  "@version": 4,
  "name": "Girls Build! Day",
  "modelId": "c68c6bdf080be9eabc0fe832877662f30e0006f14413897806ed36e5c2581b9c",
  "published": "published",
  "meta": {
    "kind": "page",
    "lastPreviewUrl": "https://dev.architecture.org/events-programs/girls-build-day?builder.space=e61abde79d954ddab2b6092044c4b5a1&builder.user.permissions=read%2Ccreate%2Cpublish%2CeditCode%2CeditDesigns%2Cadmin%2CeditLayouts%2CeditLayers&builder.user.role.name=Admin&builder.user.role.id=admin&builder.cachebust=true&builder.preview=programs-events&builder.noCache=true&__builder_editing__=true&builder.overrides.programs-events=8964fbdb195a45f58ea87697015704d2&builder.overrides.8964fbdb195a45f58ea87697015704d2=8964fbdb195a45f58ea87697015704d2&builder.options.locale=Default"
  },
  "priority": -216.5,
  "query": [
    {
      "@type": "@builder.io/core:Query",
      "property": "urlPath",
      "operator": "is",
      "value": "/events-programs/girls-build-day"
    }
  ],
  "data": {
    "title": "Girls Build! Day",
    "audience": "Youth + Teen",
    "category": "Youth + Teen",
    "productionId": 51465,
    "url": "/events-programs/girls-build-day",
    "typeOfEvent": "Festivals",
    "tessituraDescription": "Girls Build! Day",
    "imageList": [
      {
        "image": "https://cdn.builder.io/api/v1/image/assets%2Fe61abde79d954ddab2b6092044c4b5a1%2Fcf9d286bb39441edbfe2400e11ae38e4"
      }
    ],
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vestibulum nulla ut nibh vulputate egestas. ",
    "environment": "Staging",
    "tessituraSync": {
      "description": "Girls Build! Day",
      "season": {
        "description": "2024 Camps & Classes",
        "id": 256
      },
      "id": 51465,
      "model": "Programs & Events",
      "performances": [
        {
          "performanceDateTime": "2024-08-24T10:00:00-05:00",
          "productionSeason": 78776,
          "id": 78777,
          "link": "https://cart.architecture.org/78776/78777"
        }
      ]
    },
    "dates": [
      {
        "start": 1720760400000,
        "end": 1720933200000
      }
    ],
    "format": "In Person"
  },
  "metrics": {
    "clicks": 0,
    "impressions": 0
  },
  "variations": {},
  "lastUpdated": 1718760301661,
  "firstPublished": 1718119863104,
  "previewUrl": "https://dev.architecture.org/events-programs/girls-build-day",
  "testRatio": 1,
  "screenshot": "https://cdn.builder.io/api/v1/image/assets%2Fe61abde79d954ddab2b6092044c4b5a1%2F65d917c15aa3415bafa7acf2de96089e",
  "createdBy": "cy9X3DS39dP1jXkPQ6ZifxZojCh2",
  "lastUpdatedBy": "cy9X3DS39dP1jXkPQ6ZifxZojCh2",
  "folders": []
} -->
